import dynamic from "next/dynamic";
import React, { Suspense } from "react";

import { getComponentForMagnoliaTemplate } from "@/magnolia.config";

const EditableArea = dynamic(
  () => import("../components/MgnlEditor/EditableArea")
);

export type NodeWithIsPreview<T extends Wif.Mgnl.Node> = T & { isPreview?: boolean };

export type SmartEditableAreaProps = {
  slotNode: Wif.Mgnl.Node;
  isPreview?: boolean;
}

export function SmartEditableArea(props: SmartEditableAreaProps): React.JSX.Element {
  const { slotNode, isPreview } = props;

  if (isPreview) {
    return (
      <Suspense>
        <EditableArea className={"EditableArea"} content={slotNode} />
      </Suspense>
    );
  }

  const pageNodes: Wif.Mgnl.Node[] = (slotNode?.["@nodes"] || [])
    .map((name) => slotNode?.[name])
    .filter(Boolean);

  return (
    <>
      {pageNodes.map((node) => {
        const NodeComponent = getComponentForMagnoliaTemplate(
          node["mgnl:template"]
        );

        if (!NodeComponent) return null;

        return <NodeComponent key={node["@id"]} {...node} />;
      })}
    </>
  );
}
