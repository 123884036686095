import Head from "next/head";

import NewsArticleSchemaScript from "./NewsArticleSchemaScript";
import { NodeWithIsPreview, SmartEditableArea } from "@/util/SmartEditableArea";

export function Page(props: NodeWithIsPreview<Wif.Mgnl.Page>): JSX.Element | null {
  return (
    <>
      <Head>
        <NewsArticleSchemaScript pageContent={props} />
      </Head>

      <SmartEditableArea isPreview={props.isPreview} slotNode={props.main} />
    </>
  );
}
